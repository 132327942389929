<template>
  <van-row class="buytrain">
    <van-image class="pic_margin" width="100vw" src="/banner_aircraft.jpg" />

    <van-row class="pic-next-cont itembk">
      <van-row style="padding: 10px">
        <van-tabs v-model="active" @change="changeSingle">
          <van-tab title="单程" title-style="font-size:1.1rem"></van-tab>
          <van-tab title="往返" title-style="font-size:1.1rem"></van-tab>
        </van-tabs>
        <van-divider style="margin-top: 0;" />
        <van-notice-bar v-if="tips" left-icon="info-o">申请单所选城市没有机场，请调整行程。</van-notice-bar>

        <!-- 始末站选择cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="space-between" align="bottom" style="height: 20px;">
              <van-col>
                <van-row type="flex" justify="center">
                  <span style="font-size: 10px;color: #adadad">出发城市</span>
                </van-row>
              </van-col>
              <van-col>
                <van-row type="flex" justify="center">
                  <div style="font-size: 10px;color: #adadad"></div>
                </van-row>
              </van-col>
              <van-col>
                <van-row type="flex" justify="center">
                  <span style="font-size: 10px;color: #adadad">到达城市</span>
                </van-row>
              </van-col>
            </van-row>
            <van-row type="flex" justify="space-between" align="center">
              <van-col span="8">
                <van-row
                  type="flex"
                  justify="start"
                  @click="chooseTrainStation('aircraft/setAircraftOrderFrom')"
                >
                  <h4 class="animate__animated animate__delay-08" ref="left">
                    <span :class="aircraftOrderInfo.from=='请选择'?'red':''">{{aircraftOrderInfo.from}}</span>
                  </h4>
                </van-row>
              </van-col>
              <van-col span="8">
                <van-row @click="changeCity" type="flex" justify="center">
                  <img class="change-icon animate__animated" src="/changeIcon.png" ref="change" />
                </van-row>
              </van-col>
              <van-col span="8">
                <van-row
                  type="flex"
                  justify="end"
                  @click="chooseTrainStation('aircraft/setAircraftOrderTo')"
                >
                  <h4 class="animate__animated animate__delay-08" ref="right">
                    <span :class="aircraftOrderInfo.to=='请选择'?'red':''">{{aircraftOrderInfo.to}}</span>
                  </h4>
                </van-row>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        <!-- 始末站选择cell -->
        <!-- 出发日期选择cell -->
        <van-cell>
          <template slot="title">
            <van-row
              type="flex"
              justify="space-between"
              align="end"
              style="height: 54px"
              @click="show_calendar = true"
            >
              <van-col>
                <p class="toptitle">出发日期</p>
                <span class="h4">
                  {{aircraftTempTime[0]|comverTime('MM月DD日')}}
                  <small>{{aircraftTempTime[0]| comverTime('ddd')}}</small>
                </span>
              </van-col>
              <van-col
                :class="'animate__animated animate__delay-08 '+(active==1?'animate__fadeInRight':'animate__fadeOutRight')"
              >
                <p class="hidep">.</p>
                <p class="toptitle">-{{aircraftTempTime|diffdate()}}天-</p>
              </van-col>
              <van-col
                :class="'animate__animated animate__delay-08 '+(active==1?'animate__fadeInRight':'animate__fadeOutRight')"
              >
                <p class="toptitle">返程日期</p>
                <span class="h4">
                  {{aircraftTempTime[1]|comverTime('MM月DD日')}}
                  <small>{{aircraftTempTime[1]| comverTime('ddd')}}</small>
                </span>
              </van-col>
            </van-row>
          </template>
        </van-cell>
        <!-- 出发日期cell -->
        <!-- 搜索按钮cell -->
        <van-cell>
          <template slot="title">
            <van-row type="flex" justify="center" align="center" style="height: 60px">
              <van-button
                style="border-radius: 8px;font-size: 16px"
                block
                @click="searchTrain"
                color="linear-gradient(to right, #4bb0ff, #2c50d4)"
              >搜 索</van-button>
            </van-row>
          </template>
        </van-cell>
        <!-- 搜索按钮cell -->
      </van-row>
    </van-row>

    <History ref="history" :historyKey="'aircraftHistory'" @setCity="setCity" />

    <!--日期选择-->
    <van-calendar
      v-model="show_calendar"
      color="#198BDA"
      confirm-text="确定"
      :type="aircraftType.isSingle?'single':'range'"
      :show-confirm="false"
      :default-date="aircraftType.isSingle?new Date(aircraftTempTime[0]):aircraftTempTime.map(item => { return new Date(item) })"
      @confirm="calendarConfirm"
    />
    <!-- 遮罩层 -->
    <LoadingCircle :show="loading" />
  </van-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import logic from "@/services/commonLogic";
import LoadingCircle from "@/components/LoadingCircle";
import History from "@/components/History";
import { DiffDate, AddDate, DepartureDate } from "@/services/date.js";
import { checkCity } from "@/services/aircraft.js";
export default {
  name: "BuyTrain",
  components: { LoadingCircle, History },
  computed: {
    ...mapGetters([
      "travelInfo",
      "aircraftOrderInfo",
      "aircraftType",
      "aircraftTempTime",
    ]),
    // 单程还是往返控制
    active: {
      get() {
        return this.aircraftType.isSingle ? 0 : 1;
      },
      set(val) {
        return val;
      },
    },
    // 是否为返程，终到站等于行程出发地
    isBack: function () {
      return (
        this.aircraftOrderInfo.to == this.travelInfo.travelFrom &&
        this.active == 0
      );
    },
    // 是否提示？（匹配不到行程单的车站时候需要提示）
    tips: function () {
      return this.aircraftOrderInfo.to == "请选择" || this.aircraftOrderInfo.from == "请选择";
    },
  },
  mounted() {
    window.setTitle("选择城市");
    // 如果表单为空给个默认值
    if (!(0 in this.aircraftOrderInfo.date)) {
      let tempInfo = {
        date: [
          DepartureDate(this.travelInfo.startDate),
          DepartureDate(this.travelInfo.endDate),
        ], // 出发日期
        from: this.travelInfo.travelFrom, // 出发站
        to: this.travelInfo.travelTo, // 终点站
      };
      // 筛选异常车站。
      tempInfo.to = checkCity(tempInfo.to).name || "请选择";
      tempInfo.from = checkCity(tempInfo.from).name || "请选择";
      this.$store.dispatch("aircraft/setAircraftOrderInfo", tempInfo);
    }
    // 如果缓存为空，给个默认值
    if (this.aircraftTempTime.length == 0) {
      let start = DepartureDate(this.travelInfo.startDate);
      let end = DepartureDate(this.travelInfo.endDate);
      if (start == end) end = AddDate(end);
      this.$store.dispatch("aircraft/setAircraftTempTime", [start, end]);
    }
  },
  data() {
    return {
      showTempTime: [], // 缓存时间的缓存。。vue允许修改它
      loading: false,
      canChange: true, // 是否可以点击交换城市
      show_calendar: false, // 展示日历
    };
  },
  methods: {
    // 历史记录选择城市
    setCity(data) {
      this.aircraftOrderInfo.to = data.to;
      this.aircraftOrderInfo.from = data.from;
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      );
    },

    // 交换城市动画，1秒禁止点击，过度效果参考animate.css 重写了部分动画效果在全局样式中查看
    changeCity() {
      if (this.canChange) {
        this.canChange = false;
        // 解构置换数据
        [this.aircraftOrderInfo.from, this.aircraftOrderInfo.to] = [
          this.aircraftOrderInfo.to,
          this.aircraftOrderInfo.from,
        ];
        if (this.active == 0) this.aircraftTempTime.reverse();
        // 动画由css实现
        this.$refs.left.classList.add("animate__doubleSlideInRight");
        this.$refs.right.classList.add("animate__doubleSlideInLeft");
        this.$refs.change.classList.add("animate__flip");
        setTimeout(() => {
          this.canChange = true;
          this.$refs.left.classList.remove("animate__doubleSlideInRight");
          this.$refs.right.classList.remove("animate__doubleSlideInLeft");
          this.$refs.change.classList.remove("animate__flip");
        }, 1000);
        // 更新vuex
        this.$store.dispatch(
          "aircraft/setAircraftOrderInfo",
          this.aircraftOrderInfo
        );
      }
    },

    // 修改往返和单程
    changeSingle(name, title) {
      this.$store.dispatch("aircraft/setAircraftOrderSingle", title);
    },

    // 选择城市
    chooseTrainStation(vuxFunction) {
      this.loading = true;
      setTimeout(() => {
        this.$router.push({
          path: "/selectCity",
          query: logic.queryPathEncrypt({ function: vuxFunction }),
        });
      }, 200);
    },

    // 日历 选择日期。仅更新缓存时间，订单时间只有在点确定按钮时候才会更新
    calendarConfirm(date) {
      if (!(0 in date)) {
        let end = this.aircraftTempTime[1];
        date = [date, end];
      }
      this.$store.dispatch("aircraft/setAircraftTempTime", date);
      this.show_calendar = false;
    },

    // 搜索按钮
    searchTrain() {
      // 如果 你没乖乖选票
      if (
        !this.aircraftOrderInfo.from ||
        this.aircraftOrderInfo.from == "请选择" ||
        !this.aircraftOrderInfo.to ||
        this.aircraftOrderInfo.to == "请选择"
      ) {
        return this.$toast.fail({
          duration: 1000,
          message: "请选择城市",
        });
      }
      // 正常继续
      this.$refs.history.append(this.aircraftOrderInfo);
      if (!this.aircraftType.isSingle) {
        this.$store.dispatch("aircraft/setAircraftStep", 0);
      }
      this.aircraftOrderInfo.date =
        this.active == 1 ? this.aircraftTempTime.sort() : this.aircraftTempTime;
      this.$store.dispatch(
        "aircraft/setAircraftOrderInfo",
        this.aircraftOrderInfo
      );
      this.$router.push({
        path: this.active == 0 ? "/searchAircraftSingle" : "searchAircraftTo",
      });
    },
  },
  watch: {
    // 切换往返修改显示时间
    active: {
      handler: function () {
        if (this.active == 1)
          this.$store.dispatch(
            "aircraft/setAircraftTempTime",
            this.aircraftTempTime.sort()
          );
        if (this.active == 0 && this.isBack)
          this.$store.dispatch(
            "aircraft/setAircraftTempTime",
            this.aircraftTempTime.sort().reverse()
          );
      },
    },
  },
  filters: {
    // 间隔几日
    diffdate: function (date) {
      return DiffDate(date[0], date[1]);
    },
  },
};
</script>

<style lang="less" src="@/styles/buyCommon.less" scoped></style>
<style lang="less">
.buytrain {
  .van-hairline--top-bottom::after {
    border: 0;
  }
}
.change-icon {
  width: 2rem;
  height: 2rem;
}
</style>